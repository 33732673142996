<template lang="pug">
div
	h2 Users
	ul(v-if="projects")
		li(v-for="(user, index) in users" :index="index" :key="user.id")
			span {{user}}
	p(v-else) Create your first project.
</template>

<script>

export default {
	name: 'AccountUsers',
	computed: {
		users() {
			return this.$store.state.users;
		},
	},
	methods: {
	},
};
</script>

<style lang="scss">
</style>